import React, { useEffect, useState } from 'react';
import povar from './images/povar.png';
import image1 from './images/i1.png';
import image2 from './images/i2.png';
import image3 from './images/i3.png';


function TelegramWebApp() {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Создаем и добавляем элемент скрипта
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-web-app.js';
        script.async = true;
        document.body.appendChild(script);

        // Проверяем загрузку скрипта
        script.onload = () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.ready();
                const user = window.Telegram.WebApp.initDataUnsafe.user;
                if (user) {
                    setUsername(user.username || user.first_name || 'Гость');
                } else {
                    setError('Не удалось получить информацию о пользователе.');
                }
            } else {
                setError('Пожалуйста, откройте это приложение через Telegram!');
            }
        };

        // Очищаем скрипт при размонтировании компонента
        return () => {
            document.body.removeChild(script);
        };
    }, []);


    return (
        <main style={{ textAlign: 'center', padding: '20px' }}>
            {error ? (
                <div>
                    <h1>Ошибка</h1>
                    <p>{error}</p>
                </div>
            ) : (
                <div className="success-container">
                    <link href="https://fonts.googleapis.com/css2?family=Involve:wght@400;700&display=swap"
                          rel="stylesheet"/>
                    <div className="povar">
                        <img src={povar} alt="Повар"/>
                    </div>
                    <div className="text">
                        <h1>Привет, {username}!</h1>
                        <p>🎮 Добро пожаловать в мир *Restaurant of Miracles*! 🎩</p>
                        <p>Мы готовим для тебя уникальное кулинарное приключение. ✨<span>
                            Пока игра находится в разработке,
                        </span> мы
                            приглашаем тебя воспользоваться нашим ботом для развлечений и полезных задач. 🍴🪄</p>
                        <p>Не забудь: твои отзывы и предложения очень важны для нас! 💬</p>
                        <a href="https://t.me/rom_play_bot">Перейти в бота</a>
                    </div>
                    <div className="image-gallery">
                        <img src={image1} alt="Image 1"/>
                        <img src={image2} alt="Image 2"/>
                        <img src={image3} alt="Image 3"/>
                    </div>

                </div>
            )}
        </main>
    );
}

export default TelegramWebApp;
