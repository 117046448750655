// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: #f8f9fc;
  font-family: 'Involve', sans-serif;
  margin: 0;
  padding: 0;
}

.success-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.povar img {
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.text {
  color: #333;
  text-align: center;
}

.text h1 {
  font-size: 2.5em;
  margin: 20px 0;
  color: #8621f1;
}

.text p {
  font-size: 1.2em;
  line-height: 1.5;
  margin: 10px 0;
}

.text a {
  display: inline-block;
  padding: 15px 30px;
  background: #8621f1;
  color: #fff;
  border-radius: 25px 10px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1em;
  margin-top: 20px;
  transition: background 0.3s;
}

.text a:hover {
  background: #8621f1;
}

p span {
  color: #8621f1
}
.image-gallery {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.image-gallery img {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  max-width: 30%;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kCAAkC;EAClC,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,wBAAwB;EACxB,qBAAqB;EACrB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;AACF;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,wCAAwC;EACxC,cAAc;EACd,YAAY;AACd","sourcesContent":["body {\n  background: #f8f9fc;\n  font-family: 'Involve', sans-serif;\n  margin: 0;\n  padding: 0;\n}\n\n.success-container {\n  max-width: 800px;\n  margin: auto;\n  padding: 20px;\n  background: #ffffff;\n  border-radius: 15px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.povar img {\n  max-width: 100%;\n  border-radius: 15px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);\n}\n\n.text {\n  color: #333;\n  text-align: center;\n}\n\n.text h1 {\n  font-size: 2.5em;\n  margin: 20px 0;\n  color: #8621f1;\n}\n\n.text p {\n  font-size: 1.2em;\n  line-height: 1.5;\n  margin: 10px 0;\n}\n\n.text a {\n  display: inline-block;\n  padding: 15px 30px;\n  background: #8621f1;\n  color: #fff;\n  border-radius: 25px 10px;\n  text-decoration: none;\n  font-weight: bold;\n  font-size: 1.1em;\n  margin-top: 20px;\n  transition: background 0.3s;\n}\n\n.text a:hover {\n  background: #8621f1;\n}\n\np span {\n  color: #8621f1\n}\n.image-gallery {\n  display: flex;\n  justify-content: space-around;\n  margin-top: 20px;\n}\n\n.image-gallery img {\n  border-radius: 10px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);\n  max-width: 30%;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
