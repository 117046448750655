// src/App.js
import React from 'react';
import TelegramWebApp from './TelegramWebApp';
import './App.css';

function App() {
  return (
      <div className="App">
          <script src="https://telegram.org/js/telegram-web-app.js"></script>
          <script>
              window.onload = function() {
              console.log('Telegram Web SDK:', window.Telegram)
          };
          </script>
          <TelegramWebApp/>
      </div>
  );
}

export default App;
